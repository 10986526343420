function SectionTestimonials1() {
    return (
        <>
            <div className="sx-testimonial-bx-1">
                <div className="owl-carousel testimonial-2-wrap owl-btn-vertical-center">
                    {/*One block*/}
                    <div className="item">
                        <div className="testimonial-2  hover-animation-1">
                            <div className="testimonial-text">
                                <span className="testimonial-quote sx-text-primary"><i className="flaticon-quote" /></span>
                                <p>Digital Democracy Platform enhance democratic processes, engage citizens, and increase participation in governance. The aim is to make democratic processes more transparent, inclusive, and efficient, allowing for greater citizen involvement in decision-making. </p>
                            </div>
                            <div className="testimonial-detail">
                                <div className="testimonial-user-info">
                                    <h4 className="testimonial-name">Arbinda Poudel</h4>
                                    <span className="testimonial-position">Founder Of Companey</span>
                                </div>
                            </div>
                        </div>
                    </div>
                    {/*One two*/}
                    <div className="item">
                        <div className="testimonial-2  hover-animation-1">
                            <div className="testimonial-text">
                                <span className="testimonial-quote sx-text-primary"><i className="flaticon-quote" /></span>
                                <p>Digital Democracy Platform enhance democratic processes, engage citizens, and increase participation in governance. The aim is to make democratic processes more transparent, inclusive, and efficient, allowing for greater citizen involvement in decision-making.</p>
                            </div>
                            <div className="testimonial-detail">
                                <div className="testimonial-user-info">
                                    <h4 className="testimonial-name">Nagendra Adhikari</h4>
                                    <span className="testimonial-position">Founder Of Companey</span>
                                </div>
                            </div>
                        </div>
                    </div>
                    {/*One three*/}
                    <div className="item">
                        <div className="testimonial-2  hover-animation-1">
                            <div className="testimonial-text">
                                <span className="testimonial-quote sx-text-primary"><i className="flaticon-quote" /></span>
                                <p>Digital Democracy Platform enhance democratic processes, engage citizens, and increase participation in governance. The aim is to make democratic processes more transparent, inclusive, and efficient, allowing for greater citizen involvement in decision-making.</p>
                            </div>
                            <div className="testimonial-detail">
                                <div className="testimonial-user-info">
                                    <h4 className="testimonial-name">Mahendra Rajbhandari</h4>
                                    <span className="testimonial-position">Founder Of Companey</span>
                                </div>
                            </div>
                        </div>
                    </div>
                    {/*One four*/}
                    <div className="item">
                        <div className="testimonial-2  hover-animation-1">
                            <div className="testimonial-text">
                                <span className="testimonial-quote sx-text-primary"><i className="flaticon-quote" /></span>
                                <p>Digital Democracy Platform enhance democratic processes, engage citizens, and increase participation in governance. The aim is to make democratic processes more transparent, inclusive, and efficient, allowing for greater citizen involvement in decision-making.</p>
                            </div>
                            <div className="testimonial-detail">
                                <div className="testimonial-user-info">
                                    <h4 className="testimonial-name">Aayush Poudel</h4>
                                    <span className="testimonial-position">Founder Of Companey</span>
                                </div>
                            </div>
                        </div>
                    </div>
                    {/* One Five> */}
                    <div className="item">
                        <div className="testimonial-2  hover-animation-1">
                            <div className="testimonial-text">
                                <span className="testimonial-quote sx-text-primary"><i className="flaticon-quote" /></span>
                                <p>Digital Democracy Platform enhance democratic processes, engage citizens, and increase participation in governance. The aim is to make democratic processes more transparent, inclusive, and efficient, allowing for greater citizen involvement in decision-making.</p>
                            </div>
                            <div className="testimonial-detail">
                                <div className="testimonial-user-info">
                                    <h4 className="testimonial-name">Kishor Dutta Baral</h4>
                                    <span className="testimonial-position">Founder Of Companey</span>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </>
    )
}

export default SectionTestimonials1;