import { publicUrlFor } from "../../../../globals/constants";
import ItodoImage from "../../../elements/itodo-img";

function SectionOurInfo1() {
    return (
        <>
            <div className="sx-mv-bx1-content-wrap">
                <div className="row">
                    <div className="col-lg-6 col-md-12">
                        <div className="sx-about-bx1-content">
                            <h2 className="sx-title">Mission And Vision</h2>
                            <span className="sx-title-2">Mission Statement.</span>
                            <p>"Our mission is to harness the power of artificial intelligence and innovative technologies to solve complex problems, drive business transformation, and improve the quality of life for individuals and communities. We strive to deliver cutting-edge AI solutions that are ethical, accessible, and reliable, empowering our clients to achieve their goals and lead in a rapidly evolving digital landscape."</p>
                            <span className="sx-title-2">Vision Statement.</span>
                            <p>"Our vision is to be a global leader in artificial intelligence, pioneering advancements that revolutionize industries and create a smarter, more connected world. We envision a future where AI seamlessly integrates into everyday life, enhancing human capabilities and fostering a more inclusive and prosperous society. Through relentless innovation and a commitment to ethical AI practices, we aim to set new standards for excellence in the IT industry."</p>
                            <span className="sx-title-2">Success Measurement.</span>
                            <div className="progress">
                                <div className="progress-bar sx-bg-primary" role="progressbar" style={{ width: '97%' }} aria-valuenow={97} aria-valuemin={0} aria-valuemax={100}><span>Transparency and Integrity</span></div>
                            </div>
                            <div className="progress">
                                <div className="progress-bar sx-bg-primary" role="progressbar" style={{ width: '85%' }} aria-valuenow={85} aria-valuemin={0} aria-valuemax={100}><span>Innovative Solutions</span></div>
                            </div>
                            <div className="progress">
                                <div className="progress-bar sx-bg-primary" role="progressbar" style={{ width: '55%' }} aria-valuenow={55} aria-valuemin={0} aria-valuemax={100}><span>Community Engagement</span></div>
                            </div>
                        </div>
                    </div>
                    <div className="col-lg-6 col-md-12">
                        <div className="sx-mv-bx1-media">
                            <div className="sx-dot-bx-right"><ItodoImage src="images/about/dotted-pic.png" alt="image" /></div>
                            <div className="sx-mv-img-outer">
                                <div className="sx-mv-img-section-2">
                                    <ItodoImage src="images/about/p1-1.jpg" alt="image" />
                                </div>
                                <div className="sx-mv-img-section-1-wrap">
                                    <div className="sx-mv-video">
                                        <a href="https://www.youtube.com/watch?v=c1XNqw2gSbU" className="mfp-video circle">
                                            <i className="flaticon-play" />
                                        </a>
                                    </div>
                                    <div className="sx-mv-img-section-1" style={{ backgroundImage: `url(${publicUrlFor("images/about/right-pic.png")})` }} />
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </>
    )
}

export default SectionOurInfo1;