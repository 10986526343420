import SectionVideo2 from "../../../sections/home/index2/section-video2"
import SectionWhyChooseUs4 from "../../../sections/company/about/section-why-choose-us4"
import SectionClients2 from "../../../sections/home/index2/section-clients2"
import SectionCounter1 from "../../../sections/home/index/section-counter1";
import ItodoImage from "../../../elements/itodo-img";
import { useEffect } from "react";
import { loadScript } from "../../../../globals/constants";

//function ServicesDetailPage() {
function SmartFarmarDetailPage(){
    useEffect(()=>{
        loadScript("js/custom.js")
    })

    return (
        <>
            {/* service detail SECTION START */}
            <div className="section-full p-b80 sx-ser-detail-outer sx-bg-white">
                <div className="sx-ser-detail-l-pic p-b80">
                    <div className="sx-media  relative ">
                        <ItodoImage src="images/services/service-detail-smart-farmer.jpg" alt="image" />
                        <div className="sx-bnr-outline-text">
                            <h1> NEPALI KISHAN</h1>
                        </div>
                    </div>
                </div>
                <div className="container">
                    <div className="section-content">
                        <div className="sx-our-service-detail">
                            {/* TITLE START */}
                            <div className="section-head2">
                                <div className="sx-head-s-title">Why Choose Us</div>
                                <div className="sx-head-l-title">
                                    <h2 className="sx-title2">Benifits of our creative AI Cunsultant for leaders.</h2>
                                </div>
                            </div>
                            {/* TITLE END */}
                            <div className="sx-our-service-info m-b80">
                                <p>In the ever-evolving political landscape, 
                                    staying ahead requires innovation and the strategic use of technology. 
                                    Creative AI Consultants offer political leaders the tools and insights to leverage artificial intelligence for effective governance, 
                                    enhanced voter engagement, and successful campaigns.
                                </p>
                                
                                <div className="row sx-about-icon-bx1-column p-t50 p-b20">
                                    <div className="col-lg-4 col-md-6 m-b30">
                                        <div className="sx-icon-bx-2">
                                            <div className="sx-media">
                                                <i className="flaticon-mouse-1" />
                                            </div>
                                            <div className="sx-icon-bx-info">
                                                <span className="sx-icon-bx-title">Enhanced Campaign Effectiveness</span>
                                                <span className="sx-icon-bx-title-info">Tailoring strategies based on precise AI-driven insights to maximize impact.</span>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="col-lg-4 col-md-6 m-b30">
                                        <div className="sx-icon-bx-2">
                                            <div className="sx-media">
                                                <i className="flaticon-download" />
                                            </div>
                                            <div className="sx-icon-bx-info">
                                                <span className="sx-icon-bx-title">Improved Voter Relations</span>
                                                <span className="sx-icon-bx-title-info">Engaging voters with personalized and relevant messaging.</span>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="col-lg-4 col-md-6 m-b30">
                                        <div className="sx-icon-bx-2">
                                            <div className="sx-media">
                                                <i className="flaticon-wordpress" />
                                            </div>
                                            <div className="sx-icon-bx-info">
                                                <span className="sx-icon-bx-title">Informed Policy Making</span>
                                                <span className="sx-icon-bx-title-info">Making decisions backed by comprehensive data analysis.</span>
                                            </div>
                                        </div>
                                    </div>


                                    <div className="col-lg-4 col-md-6 m-b30">
                                        <div className="sx-icon-bx-2">
                                            <div className="sx-media">
                                                <i className="flaticon-wordpress" />
                                            </div>
                                            <div className="sx-icon-bx-info">
                                                <span className="sx-icon-bx-title">Efficient Resource Management</span>
                                                <span className="sx-icon-bx-title-info">Allocating campaign resources strategically for optimal outcomes.</span>
                                            </div>
                                        </div>
                                    </div>


                                    <div className="col-lg-4 col-md-6 m-b30">
                                        <div className="sx-icon-bx-2">
                                            <div className="sx-media">
                                                <i className="flaticon-mouse-1" />
                                            </div>
                                            <div className="sx-icon-bx-info">
                                                <span className="sx-icon-bx-title">Real-Time Adaptability</span>
                                                <span className="sx-icon-bx-title-info">Adjusting strategies quickly in response to AI-monitored trends and sentiments.</span>
                                            </div>
                                        </div>
                                    </div>

                                    <div className="col-lg-4 col-md-6 m-b30">
                                        <div className="sx-icon-bx-2">
                                            <div className="sx-media">
                                                <i className="flaticon-download" />
                                            </div>
                                            <div className="sx-icon-bx-info">
                                                <span className="sx-icon-bx-title">Improved Voter Relations</span>
                                                <span className="sx-icon-bx-title-info">Engaging voters with personalized and relevant messaging.</span>
                                            </div>
                                        </div>
                                    </div>


                                </div>
                                <p>For political leaders, partnering with a DOORjE Creative AI Consultant can revolutionize the way campaigns are run and policies are crafted. By harnessing the power of AI, political leaders can achieve more effective governance, engage with constituents in meaningful ways, and lead successful campaigns that resonate with voters.
                                </p>
                            </div>
                            
                            <SectionCounter1 />
                        </div>
                    </div>
                </div>
            </div>
            {/* service detail SECTION END */}
{/*             
            <SectionVideo2 />
            <SectionWhyChooseUs4 />
            <SectionClients2 /> */}
        </>
    )
}
export default SmartFarmarDetailPage;
//export default ServicesDetailPage;